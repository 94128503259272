import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ObtenerTecnicosDependencia } from '../helpers/UsuariosHelper';
import { ReportesContext } from '../pages/ReportesPage'

export const ModalAsignarTecnico = () => {
    const {modalAsignarTecnico, setModalAsignarTecnico, reporteSeleccionado, Reportes} = useContext(ReportesContext);
    const [tecnicos, setTecnicos] = useState();

    console.log('reporte seleccionado para asignar tecnico', reporteSeleccionado)

    const handleModalClose = () => {
        setModalAsignarTecnico(false);
    }

    const obtenerTecnicos = () => {
        const data = new FormData();
        data.append('idDependencia', reporteSeleccionado?.IdDependencia)
        ObtenerTecnicosDependencia(data).then(response =>{
            console.log('tecnicos obtenidos', response)
            if(response?.length) {
                setTecnicos(response);
            } else {
                setTecnicos();
            }
        })
    }

    useEffect(() => {
        obtenerTecnicos();
    }, [reporteSeleccionado]);
    
    const asignarTecnico = (idTecnico) => {
        const data = new FormData();
        data.append('idTecnico', idTecnico);
        data.append('idReporte', reporteSeleccionado?.IdReporte)
        fetch(process.env.REACT_APP_API_URL+'/api.reportesp.php?funcion=asignarTecnico',{
          method:'POST',
          body:data
        })
        .then(response => response.json())
        .then(datos => {
            setModalAsignarTecnico(false)
            Reportes();
          console.log('datos de asignacion', datos);
        })
        .catch(error => {
          console.log('Ocurrio un error', error)
        })        
      }


  return (
    <Dialog open={modalAsignarTecnico} onClose={handleModalClose}>
        <DialogTitle>
            Asignar Técnico
        </DialogTitle>
        <DialogContent>
            {
                tecnicos?.map(tecnico => (
                    <>
                        <div
                            onClick={() => {asignarTecnico(tecnico.Usuarios_IdUsuario)}}
                            style={{
                                marginTop:5
                            }}
                        >
                            {tecnico.Usuarios_Nombre+' '+tecnico.Usuarios_ApellidoMaterno}
                        </div>
                        <Divider />
                    </>
                ))
            }
        </DialogContent>
    </Dialog>
  )
}
